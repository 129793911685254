import React from "react";
import { Route, RouterState } from "mobx-state-router";
import { EnsureProfileLoadedHook, UserAuthorizedOnlyHook, UserRouteToRootIfAuthorizedHook } from "src/routing/hooks";
import { convertRoutes } from "src/routing/route";
import {
    AccountConfirmPage,
    LoginPage,
    MasterPage,
    AboutCheckPage,
    TransferPage,
    PayPage,
    ReceivePage,
    NewCheckPage,
    PayHistoryPage,
    AboutTransactionsPage,
    NotFoundPage,
    ReplenishmentPage,
} from "./routing/LazyRoutes";

export enum UserRouteNames {
    login = "user-login",
    confirm = "user-confirm",
    masterPage = "user-master-page",
    aboutCheckPage = "user-aboutCheck-page",
    payPage = "user-pay-page",
    transferPage = "user-transfer-page",
    receivePage = "user-receive-page",
    newCheckPage = "user-newCheck-page",
    payHistoryPage = "user-payHistory-page",
    aboutTransactionsPage = "user-aboutTransactions-page",
    replenishmentPage = "user-replenishment-page",
    notFound = "not-found",
}

export const UserViewMap = {
    [UserRouteNames.login]: <LoginPage />,
    [UserRouteNames.masterPage]: <MasterPage />,
    [UserRouteNames.aboutCheckPage]: <AboutCheckPage />,
    [UserRouteNames.replenishmentPage]: <ReplenishmentPage />,
    [UserRouteNames.payHistoryPage]: <PayHistoryPage />,
    [UserRouteNames.aboutTransactionsPage]: <AboutTransactionsPage />,
    [UserRouteNames.transferPage]: <TransferPage />,
    [UserRouteNames.payPage]: <PayPage />,
    [UserRouteNames.receivePage]: <ReceivePage />,
    [UserRouteNames.newCheckPage]: <NewCheckPage />,
    [UserRouteNames.confirm]: <AccountConfirmPage />,
    [UserRouteNames.notFound]: <NotFoundPage />,
};

const UserRoutes: Route[] = convertRoutes([
    {
        pattern: "/check/:id",
        name: UserRouteNames.aboutCheckPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root, to) => {
            root.shellStore.title = `Счет ${to.params["id"]}`;
            await root.checkInfoStore.getCheck(to.params["id"]);
        },
    },
    {
        pattern: "/check/:id/replenishment",
        name: UserRouteNames.replenishmentPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: (root, to) => {
            root.shellStore.title = `Пополнение Счета ${to.params["id"]}`;
            root.replenishmentStore.checkId = to.params["id"];
            root.replenishmentStore.reset();
        },
    },
    {
        pattern: "/pay-history/:id",
        name: UserRouteNames.payHistoryPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root, to) => {
            root.shellStore.title = `История платежей по счету ${to.params["id"]}`;
            await root.payHistoryListStore.PayHistoryList(to.params["id"]);
        },
    },
    {
        pattern: "/pay-history/:id/:transactionId",
        name: UserRouteNames.aboutTransactionsPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root, to) => {
            root.shellStore.title = `Платеж ${to.params["transactionId"]}`;
            await root.payHistoryStore.PayHistory(to.params["id"], to.params["transactionId"]);
        },
    },
    // {
    //     pattern: "/pay",
    //     name: UserRouteNames.payPage,
    //     hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
    //     onEnter: (root, to) => {
    //         root.shellStore.title = `Оплата`;
    //     },
    // },
    {
        pattern: "/transfer/:id",
        name: UserRouteNames.transferPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root, to) => {
            root.shellStore.title = `Обмен валют`;
            await root.transferStore.initialize(to.params["id"]);
        },
    },
    {
        pattern: "/pay/:id?",
        name: UserRouteNames.payPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root, to) => {
            root.shellStore.title = `Перевод`;
            await root.payStore.initialize(to.params["id"]);
        },
    },
    {
        pattern: "/receive",
        name: UserRouteNames.receivePage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: (root) => {
            root.shellStore.title = `Запрос`;
        },
    },
    {
        pattern: "/new-check",
        name: UserRouteNames.newCheckPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root) => {
            root.shellStore.title = `Новый счет`;
            root.checkCreateStore.Restore();
            await root.currencyStore.GetCurrencyList();
        },
    },
    {
        pattern: "/login",
        name: UserRouteNames.login,
        hooks: [UserRouteToRootIfAuthorizedHook],
    },
    {
        pattern: "/confirm",
        name: UserRouteNames.confirm,
        hooks: [UserAuthorizedOnlyHook],
        onEnter: async (root, to) => {
            if (await root.userConfirmAccountStore.confirmAccount(to.queryParams["code"]))
                throw new RouterState(UserRouteNames.login);
        },
    },
    {
        pattern: "/not-found",
        name: UserRouteNames.notFound,
    },
    {
        pattern: "/",
        name: UserRouteNames.masterPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: async (root) => {
            root.shellStore.title = "Мои счета";
            await root.checkListStore.loadCheckList();
        },
    },
]);

export const Routes: Route[] = UserRoutes;
