import React, { FC } from "react";
import styles from "src/components/Spinner/spinner.module.css";

export const Spinner: FC = () => {
    return (
        <div className={styles.spinner} style={{ paddingTop: 30 }}>
            <div className={styles.spinner__loader} />
            <div className={styles.spinner__description}>Пожалуйста, подождите...</div>
        </div>
    );
};
