import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountDetailsDto } from "src/api";

export class CheckListStore {
    @observable list?: AccountDetailsDto[];
    @observable paginationList?: AccountDetailsDto[];
    @observable totalItems: number = 1;
    @observable page: number = 0;
    @observable take: number = 5;

    constructor(private readonly root: RootStore) {}

    @action changePage(page: number) {
        this.page = page;
        this.createPaginationList();
    }

    @action changeRow(take: number) {
        this.take = take;
        this.createPaginationList();
    }

    @action
    async loadCheckList() {
        try {
            const res = await this.root.userRpc.userAccount.getAccounts(
                this.page * this.take,
                (this.page + 1) * this.take
            );
            this.list = res.items;
            this.totalItems = res.totalCount;
            this.createPaginationList();
        } catch (e) {
            console.log(e);
        }
    }

    @action createPaginationList() {
        if (!this.list) return;
        this.paginationList = this.list.slice(this.page * this.take, (this.page + 1) * this.take);
    }
}
