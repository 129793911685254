import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { TransactionInfoDto } from "src/api";

export class PayHistoryStore {
    @observable transaction?: TransactionInfoDto;

    constructor(private readonly root: RootStore) {}

    @action
    async PayHistory(accountId: string, id: string) {
        try {
            const res = await this.root.userRpc.userAccount.getTransactionInfo(accountId, id);
            this.transaction = res.value;
        } catch (e) {
            console.log(e);
        }
    }
}
