import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountDetailsDto } from "src/api";

export class CheckInfoStore {
    @observable check?: AccountDetailsDto;

    constructor(private readonly root: RootStore) {}

    @action
    async getCheck(id: string) {
        try {
            const res = await this.root.userRpc.userAccount.getAccountInfo(id);
            this.check = res.value;
            await this.root.currencyStore.GetCurrency(this.check?.currencyId ?? "");
        } catch (e) {
            console.log(e);
        }
    }
}
