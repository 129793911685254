import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountDetailsDto, CurrencyRateDto, TransferMoneyDto } from "src/api";
import { UserRouteNames } from "src/routes";
import { validate, IsNotEmpty, NotEquals } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";

type PayStoreErrors = {
    accountTo?: string[];
    amount?: string[];
    description?: string[];
};

export class TransferStore {
    @observable account?: AccountDetailsDto;
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    accountTo: string = "";
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    description: string = "";
    @observable
    @NotEquals(0)
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    amount: number | string = "";
    @observable amountTo: number | string = "";

    @observable checkList?: AccountDetailsDto[];
    @observable currencyTarget: string = "";
    @observable currencyRate?: CurrencyRateDto;

    @observable errors: PayStoreErrors = {};
    @observable disabled: boolean = false;

    constructor(private readonly root: RootStore) {}

    get MaxItems() {
        return Number(this.account?.balance) * Number(this.currencyRate?.buy);
    }

    @action async initialize(id: string) {
        this.restore();
        await this.loadAccount(id);
        await this.loadCheckList();
    }

    @action restore() {
        this.amount = "";
        this.description = "";
        this.accountTo = "";
        this.amountTo = "";
        this.currencyTarget = "";
        this.currencyRate = undefined;
        this.errors = {};
    }

    @action
    async loadAccount(id: string) {
        try {
            const res = await this.root.userRpc.userAccount.getAccountInfo(id);
            this.account = res.value;
            await this.root.currencyStore.GetCurrency(this.account?.currencyId ?? "");
        } catch (e) {
            console.log(e);
        }
    }

    @action async loadCheckList() {
        try {
            const res = await this.root.userRpc.userAccount.getAccounts(0, 1);
            this.checkList = res.items;
        } catch (e) {
            console.log(e);
        }
    }

    @action async getRate() {
        try {
            const res = await this.root.userRpc.userAccount.getCurrencyRate(
                this.account?.currencyId ?? "",
                this.currencyTarget
            );
            this.currencyRate = res.value;
        } catch (e) {
            console.log(e);
        }
    }

    @action
    async Pay() {
        const errors = await validate(this);
        this.errors = reduceValidationErrorsToErrors(errors);

        if (errors.length || !this.account?.id) return;

        if (!this.account?.id || this.account.balance < this.amount) return;

        const data: TransferMoneyDto = {
            accountFrom: this.account.id,
            accountTo: this.accountTo,
            description: this.description,
            amount: Number(this.amount),
        };
        try {
            await this.root.userRpc.userAccount.transferCurrency(data);
            await this.root.routerStore.goTo(UserRouteNames.payHistoryPage, { id: this.account.id });
        } catch (e) {
            console.log(e);
        }
    }
}
