export interface UserRoleDto {
        id : number;
        userId : number;
        role : string;
}

export interface CreateAccountDto {
        name : string;
        currencyId : string;
}

export interface ErrorCodeV0 {
        code : string;
        description : string;
}

export interface ResultT<T> {
        value : T;
        success : boolean;
        error : ErrorCodeV0;
}

export interface AccountDetailsDto {
        name : string;
        id : string;
        currencyId : string;
        balance : number;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface TransactionInfoDto {
        id : string;
        accountId : string;
        createdAt : string;
        description : string;
        amount : number;
        commission : number;
}

export interface CurrencyDto {
        id : string;
        name : string;
}

export interface ResultV0 {
        success : boolean;
        error : ErrorCodeV0;
}

export interface TransferMoneyDto {
        accountFrom : string;
        accountTo : string;
        description : string;
        amount : number;
}

export interface CurrencyRateDto {
        currencySourceId : string;
        currencyTargetId : string;
        buy : number;
        sell : number;
}

export interface UserProfileDto {
        id : number;
        email : string;
        name : string;
}


export interface ICoreRpcProxyForRolesRpc {
    getMyRoles () : Promise<string[]>;
    getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>;
}
export class CoreRpcProxyForRolesRpc implements ICoreRpcProxyForRolesRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getMyRoles () : Promise<string[]>    {
        return this.parent.send<string[]>({Target: 'RolesRpc', MethodSignature: 'CkdldE15Um9sZXM=', Arguments: [] });
    }
    public getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>    {
        return this.parent.send<UserRoleDto[]>({Target: 'RolesRpc', MethodSignature: 'EUdldFVzZXJSb2xlc0J5SWRzjAFTeXN0ZW0uQ29sbGVjdGlvbnMuR2VuZXJpYy5MaXN0YDFbW1N5c3RlbS5JbnQ2NCwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [ids] });
    }
}
export interface ICoreRpcProxyForUserAccountRpc {
    createNewAccount (model : CreateAccountDto) : Promise<ResultT<string>>;
    getAccountInfo (id : string) : Promise<ResultT<AccountDetailsDto>>;
    getAccountTransactions (id : string) : Promise<ListResult<TransactionInfoDto>>;
    getTransactionInfo (accountId : string, id : string) : Promise<ResultT<TransactionInfoDto>>;
    getAvailableCurrency (skip : number, take : number) : Promise<ListResult<CurrencyDto>>;
    getCurrency (id : string) : Promise<ResultT<CurrencyDto>>;
    getAccounts (skip : number, take : number) : Promise<ListResult<AccountDetailsDto>>;
    canTransferTo (fromAccountId : string, toAccountId : string) : Promise<ResultV0>;
    transferCurrency (moneyDto : TransferMoneyDto) : Promise<ResultV0>;
    addCurrencyGateway (accountId : string, amount : number) : Promise<ResultV0>;
    getCurrencyRate (sourceCurrencyId : string, targetCurrencyId : string) : Promise<ResultT<CurrencyRateDto>>;
}
export class CoreRpcProxyForUserAccountRpc implements ICoreRpcProxyForUserAccountRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public createNewAccount (model : CreateAccountDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserAccountRpc', MethodSignature: 'EENyZWF0ZU5ld0FjY291bnQrREZBLlVwYW5lbC5BUEkuUnBjLlVzZXJScGMuQ3JlYXRlQWNjb3VudER0bw==', Arguments: [model] });
    }
    public getAccountInfo (id : string) : Promise<ResultT<AccountDetailsDto>>    {
        return this.parent.send<ResultT<AccountDetailsDto>>({Target: 'UserAccountRpc', MethodSignature: 'DkdldEFjY291bnRJbmZvC1N5c3RlbS5HdWlk', Arguments: [id] });
    }
    public getAccountTransactions (id : string) : Promise<ListResult<TransactionInfoDto>>    {
        return this.parent.send<ListResult<TransactionInfoDto>>({Target: 'UserAccountRpc', MethodSignature: 'FkdldEFjY291bnRUcmFuc2FjdGlvbnMLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getTransactionInfo (accountId : string, id : string) : Promise<ResultT<TransactionInfoDto>>    {
        return this.parent.send<ResultT<TransactionInfoDto>>({Target: 'UserAccountRpc', MethodSignature: 'EkdldFRyYW5zYWN0aW9uSW5mbwtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [accountId,id] });
    }
    public getAvailableCurrency (skip : number, take : number) : Promise<ListResult<CurrencyDto>>    {
        return this.parent.send<ListResult<CurrencyDto>>({Target: 'UserAccountRpc', MethodSignature: 'FEdldEF2YWlsYWJsZUN1cnJlbmN5DFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [skip,take] });
    }
    public getCurrency (id : string) : Promise<ResultT<CurrencyDto>>    {
        return this.parent.send<ResultT<CurrencyDto>>({Target: 'UserAccountRpc', MethodSignature: 'C0dldEN1cnJlbmN5C1N5c3RlbS5HdWlk', Arguments: [id] });
    }
    public getAccounts (skip : number, take : number) : Promise<ListResult<AccountDetailsDto>>    {
        return this.parent.send<ListResult<AccountDetailsDto>>({Target: 'UserAccountRpc', MethodSignature: 'C0dldEFjY291bnRzDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [skip,take] });
    }
    public canTransferTo (fromAccountId : string, toAccountId : string) : Promise<ResultV0>    {
        return this.parent.send<ResultV0>({Target: 'UserAccountRpc', MethodSignature: 'DUNhblRyYW5zZmVyVG8LU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [fromAccountId,toAccountId] });
    }
    public transferCurrency (moneyDto : TransferMoneyDto) : Promise<ResultV0>    {
        return this.parent.send<ResultV0>({Target: 'UserAccountRpc', MethodSignature: 'EFRyYW5zZmVyQ3VycmVuY3krREZBLlVwYW5lbC5BUEkuUnBjLlVzZXJScGMuVHJhbnNmZXJNb25leUR0bw==', Arguments: [moneyDto] });
    }
    public addCurrencyGateway (accountId : string, amount : number) : Promise<ResultV0>    {
        return this.parent.send<ResultV0>({Target: 'UserAccountRpc', MethodSignature: 'EkFkZEN1cnJlbmN5R2F0ZXdheQtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50NjQ=', Arguments: [accountId,amount] });
    }
    public getCurrencyRate (sourceCurrencyId : string, targetCurrencyId : string) : Promise<ResultT<CurrencyRateDto>>    {
        return this.parent.send<ResultT<CurrencyRateDto>>({Target: 'UserAccountRpc', MethodSignature: 'D0dldEN1cnJlbmN5UmF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [sourceCurrencyId,targetCurrencyId] });
    }
}
export interface ICoreRpcProxyForUserLoginRpc {
    login (username : string, password : string) : Promise<ResultT<string>>;
    loginAdmin (username : string, password : string) : Promise<ResultT<string>>;
    checkAuthToken () : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public login (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'BUxvZ2luDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [username,password] });
    }
    public loginAdmin (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'CkxvZ2luQWRtaW4NU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [username,password] });
    }
    public checkAuthToken () : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DkNoZWNrQXV0aFRva2Vu', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserProfileRpc {
    getProfile () : Promise<UserProfileDto>;
}
export class CoreRpcProxyForUserProfileRpc implements ICoreRpcProxyForUserProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getProfile () : Promise<UserProfileDto>    {
        return this.parent.send<UserProfileDto>({Target: 'UserProfileRpc', MethodSignature: 'CkdldFByb2ZpbGU=', Arguments: [] });
    }
}
export interface ICoreApi {
    roles : ICoreRpcProxyForRolesRpc;
    userAccount : ICoreRpcProxyForUserAccountRpc;
    userLogin : ICoreRpcProxyForUserLoginRpc;
    userProfile : ICoreRpcProxyForUserProfileRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.roles = new CoreRpcProxyForRolesRpc(this);
    this.userAccount = new CoreRpcProxyForUserAccountRpc(this);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.userProfile = new CoreRpcProxyForUserProfileRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    roles : CoreRpcProxyForRolesRpc;
    userAccount : CoreRpcProxyForUserAccountRpc;
    userLogin : CoreRpcProxyForUserLoginRpc;
    userProfile : CoreRpcProxyForUserProfileRpc;
}
