import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { CreateAccountDto } from "src/api";
import { UserRouteNames } from "src/routes";
import { IsNotEmpty, validate } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";

type CheckCreateStoreErrors = {
    name?: string[];
    currency?: string[];
};

export class CheckCreateStore {
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    name: string = "";
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    currency: string = "";

    @observable errors: CheckCreateStoreErrors = {};

    constructor(private readonly root: RootStore) {}

    @action Restore() {
        this.name = "";
        this.currency = "";
        this.errors = {};
    }

    @action
    async CreateCheck() {
        const errors = await validate(this);

        if (errors.length) {
            this.errors = reduceValidationErrorsToErrors(errors);
            return;
        }

        const data: CreateAccountDto = {
            name: this.name,
            currencyId: this.currency,
        };
        try {
            await this.root.userRpc.userAccount.createNewAccount(data);
            await this.root.routerStore.goTo(UserRouteNames.masterPage);
        } catch (e) {
            console.log(e);
        }
    }
}
