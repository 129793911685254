import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";

export class ReplenishmentStore {
    @observable checkId: string = "";
    @observable price: number | string = "";

    constructor(private readonly root: RootStore, private baseUrl: string) {}

    @action reset() {
        this.price = "";
    }

    @action
    async postReplenishment() {
        if (!this.checkId || !Number(this.price)) return;
        const form = document.createElement("form");
        form.action = "https://secure.mycoldspot.ru/Pay";
        form.method = "POST";
        form.target = "_top";

        function add(key: string, vl: string) {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = vl;
            form.append(input);
        }

        add("merchantId", "1");
        add("sign", "testing");
        add("price", String(this.price));
        add("returnUrl", this.returnUrl);
        add("callbackUrl", `${this.baseUrl}/api/payments/callback/secure`);
        add("orderId", this.checkId);
        add("email", "user@example.com");

        document.body.append(form);
        form.submit();
    }

    get returnUrl(): string {
        return `${window.location.protocol}//${window.location.host}${this.root.hostAppBaseUrl ?? ""}`;
    }
}
