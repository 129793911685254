import { RootStore } from "src/stores/RootStore";
import React, { ChangeEvent, FC, FormEvent, useContext, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import { TransitionHook } from "mobx-state-router/dist/types/router-store";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { ValidationError } from "@keroosha/class-validator";
import pageStyles from "src/styles/page.module.css";

export const truncateString = (str: string, num: number) => {
    return str.length > num ? str.slice(0, num) + "..." : str;
};

// export function statusToColor(status?: CheckStatus) {
//     if (status === CheckStatus.Error) return "bg-red-600";
//     if (status === CheckStatus.Pending) return "bg-blue-600";
//     if (status === CheckStatus.Approved) return "bg-green-600";
//     if (status === CheckStatus.Rejected) return "bg-red-600";
//     return "bg-gray-600";
// }
//
// export function resultToColor(status?: InfoResultRowType) {
//     if (status === InfoResultRowType.Error) return "bg-red-600";
//     if (status === InfoResultRowType.Approve) return "bg-green-600";
//     if (status === InfoResultRowType.Neither) return "bg-blue-600";
//     if (status === InfoResultRowType.Forbid) return "bg-red-600";
//     return "bg-gray-600";
// }

export const declOfNum = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export function isNullOrWhitespace(s: string | undefined | null): boolean {
    if (s) {
        if (s.length == 0) return true;
        return s.match(/^\s+$/) != null;
    } else return true;
}

export const toRussianDate = (raw?: string) => {
    if (!raw) return "";
    const clean = raw.split(".")[0];
    const date = new Date(clean);
    date.setHours(0, 0, 0, 0);
    return date.toLocaleDateString("ru");
};

export function lastElement<T>(arr: T[]) {
    return arr[arr.length - 1];
}

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}

export type TransitionHookCallback<T> = (module: T) => Promise<unknown> | void;
export type TransitionHookWithCallback<T> = (callback?: TransitionHookCallback<T>) => TransitionHook;

export const mapChangeEventToValue = <T extends {}>(callback: (x: string) => void) => (
    x: ChangeEvent<HTMLInputElement>
) => callback(x.target.value);

export const preventDefaultEvent = <T extends {}>(callback: (x: FormEvent<HTMLFormElement>) => void) => (
    x: FormEvent<HTMLFormElement>
) => {
    x.preventDefault();
    callback(x);
};

export const useRootStore = () => useContext(MobXProviderContext) as { rootStore: RootStore };

export const useEnsureStoreLoaded = (x: { loading: boolean; initialized: boolean; load: () => void }) =>
    useEffect(() => {
        if (!x.loading && !x.initialized) x.load();
    }, [x]);

// Transition hooks

export const redirectIfProfileNotFilled: TransitionHookCallback<[UserProfileStore, unknown]> = async ([user]) => {
    if (!user.profile) await user.loadProfile();
};

export const reduceValidationErrorsToErrors = <T extends {}>(errors: ValidationError[]) =>
    errors.reduce((acc, x) => ({ ...acc, [x.property]: Object.values(x.constraints ?? {}) }), {} as T);

export const computeInputStyleByErrorArray = (x: unknown[]) =>
    x.length > 0 ? pageStyles.inputError : pageStyles.inputGrey;

export function valueOrNull<T>(value: T | undefined): T | null {
    if (value === undefined) return null;
    return value;
}

export const toDTL = (millis: number) => new Date(millis).toISOString().slice(0, -1);

export function addEvent(eventName: string, callback: (e: any) => void) {
    const element = document as any;
    if (element.addEventListener) {
        element.addEventListener(eventName, (e: any) => callback(e || window.event), false);
    } else if (element.attachEvent) {
        element.attachEvent("on" + eventName, (e: any) => callback(e || window.event));
    } else {
        element["on" + eventName] = (e: any) => callback(e || window.event);
    }
}
