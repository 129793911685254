import { action, observable, runInAction } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountDetailsDto, UserProfileDto } from "src/api";

export class UserProfileStore {
    @observable profile?: UserProfileDto;
    @observable accountDetails?: AccountDetailsDto;

    constructor(private readonly root: RootStore) {}

    @action
    async loadProfile() {
        const res = await this.root.userRpc.userProfile.getProfile();
        runInAction(() => {
            this.profile = res;
        });
    }
}
