import { action, computed, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { UserLoginStore } from "src/stores/user/UserLoginStore";
import { CoreApi } from "src/api";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { Routes, UserRouteNames } from "src/routes";
import { UserConfirmAccountStore } from "src/stores/user/UserConfirmAccountStore";
import { ShellStore } from "src/stores/user/ShellStore";
import { CheckListStore } from "src/stores/user/CheckListStore";
import { CheckCreateStore } from "src/stores/user/CheckCreateStore";
import { CheckInfoStore } from "src/stores/user/CheckInfoStore";
import { PayHistoryListStore } from "src/stores/user/PayHistoryListStore";
import { TransferStore } from "src/stores/user/TransferStore";
import { PayHistoryStore } from "src/stores/user/PayHistoryStore";
import { CurrencyStore } from "src/stores/user/CurrencyStore";
import { ReplenishmentStore } from "src/stores/user/ReplenishmentStore";
import { PayStore } from "src/stores/user/PayStore";

type AuthHeaderKeys = "X-User-Auth" | "X-Admin-Auth" | "X-Jury-Auth";

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null;
    private readonly localStorageKey;

    @computed get isAuthorized() {
        return this.token != null;
    }

    constructor(path: string, authHeaderKey: AuthHeaderKeys) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = {};
            if (this.token) request.headers[authHeaderKey] = this.token;
            const res = await fetch(url, request);
            if (res.status == 401) {
                window.location.reload();
                this.resetUserToken();
                await new Promise(() => {
                    // Never
                });
            }
            return res;
        });
        const localStorageKey = "auth-token:" + authHeaderKey;
        this.localStorageKey = localStorageKey;
        this.token = window.localStorage.getItem(localStorageKey);
    }

    @action authorizedAjax(request: RequestInfo, init?: RequestInit) {
        const maybeInit = init ?? {};
        if (!maybeInit.headers) maybeInit.headers = {};
        maybeInit.headers = { ...maybeInit.headers, Authorization: `Bearer ${this.token}` };
        return fetch(request, maybeInit);
    }

    @action setUserToken(token: string) {
        this.token = token;
        window.localStorage.setItem(this.localStorageKey, token);
    }

    @action resetUserToken() {
        this.token = null;
        window.localStorage.removeItem(this.localStorageKey);
    }
}

export class RootStore {
    @observable isEmbedded: boolean;
    @observable hostAppBaseUrl: string | undefined;
    @observable embeddedAppBaseUrl: string | undefined;

    @observable anonRpc: CoreApi;
    @observable userRpc: SecureCoreApi;
    @observable routerStore = new RouterStore(this, Routes, new RouterState(UserRouteNames.notFound));
    @observable shellStore: ShellStore;
    @observable currencyStore: CurrencyStore;
    @observable replenishmentStore: ReplenishmentStore;

    @observable loginStore: UserLoginStore;
    @observable userStore: UserProfileStore;
    @observable userConfirmAccountStore: UserConfirmAccountStore;

    @observable checkCreateStore: CheckCreateStore;
    @observable checkListStore: CheckListStore;
    @observable checkInfoStore: CheckInfoStore;

    @observable transferStore: TransferStore;
    @observable payStore: PayStore;
    @observable payHistoryListStore: PayHistoryListStore;
    @observable payHistoryStore: PayHistoryStore;

    constructor(baseUrl: string = "") {
        const apiUrl = baseUrl + "/tsrpc";
        this.isEmbedded = Boolean(baseUrl);
        this.userRpc = new SecureCoreApi(apiUrl, "X-User-Auth");
        this.anonRpc = new CoreApi(apiUrl);
        this.shellStore = new ShellStore(this);
        this.currencyStore = new CurrencyStore(this);
        this.replenishmentStore = new ReplenishmentStore(this, baseUrl);

        this.loginStore = new UserLoginStore(this);
        this.userStore = new UserProfileStore(this);
        this.userConfirmAccountStore = new UserConfirmAccountStore(this);

        this.checkListStore = new CheckListStore(this);
        this.checkCreateStore = new CheckCreateStore(this);
        this.checkInfoStore = new CheckInfoStore(this);

        this.transferStore = new TransferStore(this);
        this.payStore = new PayStore(this);
        this.payHistoryListStore = new PayHistoryListStore(this);
        this.payHistoryStore = new PayHistoryStore(this);
    }
}
