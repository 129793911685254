import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { CurrencyDto } from "src/api";

export class CurrencyStore {
    @observable library: any = {};
    @observable total: number = 0;
    @observable libraryList: CurrencyDto[] = [];
    // @observable libraryListEnam: any = {};

    constructor(private readonly root: RootStore) {}

    @action
    async GetCurrencyList() {
        if (this.libraryList.length) return;
        try {
            const res = await this.root.userRpc.userAccount.getAvailableCurrency(0, 100);
            this.total = res.totalCount;
            this.libraryList = res.items;
            // this.libraryList.map((el) => (this.libraryListEnam[el.id] = el.name));
        } catch (e) {
            console.log(e);
        }
    }

    @action
    async GetCurrency(id: string) {
        const resFind: string = this.library[id];
        if (resFind) return;
        else {
            this.library[id] = "+";
            try {
                const res = await this.root.userRpc.userAccount.getCurrency(id);
                this.library[res.value.id] = res.value.name;
            } catch (e) {
                console.log(e);
            }
        }
    }
}
