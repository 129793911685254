import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { UserViewMap } from "src/routes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { AppShell } from "src/components/AppBar/AppBar";

export const UserShell = () => {
    const {
        rootStore: { routerStore, userRpc },
    } = useRootStore();

    return useObserver(() => (
        <>
            <SuspensePlaceholder>
                {userRpc.isAuthorized ? (
                    <AppShell>
                        <RouterView routerStore={routerStore} viewMap={UserViewMap} />
                    </AppShell>
                ) : (
                    <RouterView routerStore={routerStore} viewMap={UserViewMap} />
                )}
            </SuspensePlaceholder>
        </>
    ));
};
