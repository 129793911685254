import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountDetailsDto, TransferMoneyDto } from "src/api";
import { UserRouteNames } from "src/routes";
import { validate, IsNotEmpty, NotEquals } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";

type PayStoreErrors = {
    accountTo?: string[];
    amount?: string[];
    description?: string[];
};

export class PayStore {
    @observable account?: AccountDetailsDto;
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    accountTo: string = "";
    @observable
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    description: string = "";
    @observable
    @NotEquals(0)
    @IsNotEmpty({ message: "Обязателен к заполнению" })
    amount: number | string = "";

    @observable checkList?: AccountDetailsDto[];
    @observable validateTransfer: boolean = false;
    @observable validateTransferLoad: boolean = false;
    @observable validateTransferLoadError: string = "";

    @observable errors: PayStoreErrors = {};
    @observable disabled: boolean = false;

    constructor(private readonly root: RootStore) {}

    @action async initialize(id?: string) {
        this.restore();
        await this.loadCheckList();
        if (id) await this.loadAccount(id);
    }

    @action restore() {
        this.amount = "";
        this.description = "";
        this.accountTo = "";
        this.errors = {};
        this.validateTransfer = false;
        this.validateTransferLoad = false;
        this.validateTransferLoadError = "";
        this.disabled = false;
        this.account = undefined;
    }

    @action
    async loadAccount(id: string) {
        try {
            const res = await this.root.userRpc.userAccount.getAccountInfo(id);
            this.account = res.value;
            await this.root.currencyStore.GetCurrency(this.account?.currencyId ?? "");
        } catch (e) {
            console.log(e);
        }
    }

    @action async loadCheckList() {
        try {
            const res = await this.root.userRpc.userAccount.getAccounts(0, 1);
            this.checkList = res.items;
        } catch (e) {
            console.log(e);
        }
    }

    @action async canAccount() {
        if (!this.account?.id) return;
        this.validateTransferLoad = true;
        try {
            const res = await this.root.userRpc.userAccount.canTransferTo(this.account?.id, this.accountTo);
            this.validateTransfer = res.success;
            this.validateTransferLoadError = res.error.description;
            if (res.success) this.validateTransferLoadError = "";
        } catch (e) {
            console.log(e);
        }
        this.validateTransferLoad = false;
    }

    @action
    async Pay() {
        const errors = await validate(this);
        this.errors = reduceValidationErrorsToErrors(errors);

        if (errors.length || !this.account?.id) return;

        if (!this.account?.id || Number(this.account.balance) < this.amount) return;

        const data: TransferMoneyDto = {
            accountFrom: this.account.id,
            accountTo: this.accountTo,
            description: this.description,
            amount: Number(this.amount),
        };
        try {
            await this.root.userRpc.userAccount.transferCurrency(data);
            await this.root.routerStore.goTo(UserRouteNames.payHistoryPage, { id: this.account.id });
        } catch (e) {
            console.log(e);
        }
    }
}
