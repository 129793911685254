import React, { FC } from "react";
import { RouterLink } from "mobx-state-router";
import { UserRouteNames } from "src/routes";
import { useRootStore } from "src/utilities";
import clsx from "clsx";
import { useTheme, ThemeProvider, createTheme } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ChangeHistoryRounded from "@material-ui/icons/ChangeHistoryRounded";
import AccountBalance from "@material-ui/icons/AccountBalanceWallet";
// import AccountBalance from "@material-ui/icons/Account";
import CreditCardAddIcon from "@material-ui/icons/Score";
import Logout from "@material-ui/icons/ExitToApp";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { useObserver } from "mobx-react";
import { useStyles } from "src/components/AppBar/AppBarStyle";
import { UserLoginStore } from "src/stores/user/UserLoginStore";

const Header: FC<{ store: UserProfileStore; openHandel: () => void; open: boolean; title: string }> = ({
    store,
    openHandel,
    open,
    title,
}) => {
    const classes = useStyles();
    return useObserver(() => (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={openHandel}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap children={title} />
            </Toolbar>
        </AppBar>
    ));
};

const Sidebar: FC<{ loginStore: UserLoginStore; closeHandel: () => void; open: boolean; isEmbedded: boolean }> = ({
    loginStore,
    closeHandel,
    open,
    isEmbedded,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={closeHandel}>
                    {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <RouterLink routeName={UserRouteNames.masterPage}>
                    <ListItem button>
                        <ListItemIcon>
                            <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Мои счета`} />
                    </ListItem>
                </RouterLink>
                {/*<RouterLink routeName={UserRouteNames.payHistoryPage}>*/}
                {/*    <ListItem button>*/}
                {/*        <ListItemIcon>*/}
                {/*            <ChangeHistoryRounded />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={`История транзакций`} />*/}
                {/*    </ListItem>*/}
                {/*</RouterLink>*/}
                <RouterLink routeName={UserRouteNames.payPage}>
                    <ListItem button>
                        <ListItemIcon>
                            <AccountBalance />
                        </ListItemIcon>
                        <ListItemText primary={`Перевод`} />
                    </ListItem>
                </RouterLink>
                {/*<RouterLink routeName={UserRouteNames.receivePage}>*/}
                {/*    <ListItem button>*/}
                {/*        <ListItemIcon>*/}
                {/*            <MailIcon />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={`Запросить`} />*/}
                {/*    </ListItem>*/}
                {/*</RouterLink>*/}
                <RouterLink routeName={UserRouteNames.newCheckPage}>
                    <ListItem button>
                        <ListItemIcon>
                            <ChangeHistoryRounded />
                        </ListItemIcon>
                        <ListItemText primary={`Новый счет`} />
                    </ListItem>
                </RouterLink>
                {!isEmbedded && (
                    <>
                        <a href="http://localhost:8080/swagger/index.html" target="_blank">
                            <ListItem button>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Документация API`} />
                            </ListItem>
                        </a>
                        <a href={"#"} onClick={() => loginStore.LogOut()}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary={`Выйти`} />
                            </ListItem>
                        </a>
                    </>
                )}
            </List>
        </Drawer>
    );
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#2862AC",
        },
    },
});

export const AppShell: FC = ({ children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { rootStore } = useRootStore();
    const { loginStore, shellStore, userStore: store } = rootStore;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return useObserver(() => (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <Header store={store} openHandel={handleDrawerOpen} open={open} title={shellStore.title} />
                <Sidebar
                    loginStore={loginStore}
                    closeHandel={handleDrawerClose}
                    open={open}
                    isEmbedded={rootStore.isEmbedded}
                />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
        </ThemeProvider>
    ));
};
