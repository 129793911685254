import { RouteTransitionHook } from "src/routing/route";
import { RouterState } from "mobx-state-router";
import { UserRouteNames } from "src/routes";

export const UserAuthorizedOnlyHook: RouteTransitionHook = (root) => {
    if (!root.userRpc.isAuthorized) throw new RouterState(UserRouteNames.login);
};

export const EnsureProfileLoadedHook: RouteTransitionHook = async (root) => {
    if (!root.userStore.profile) await root.userStore.loadProfile();
};

export const UserRouteToRootIfAuthorizedHook: RouteTransitionHook = (root) => {
    if (root.userRpc.isAuthorized) {
        root.routerStore.goTo(new RouterState(UserRouteNames.masterPage));
    }
};
