import React from "react";
import createBrowserHistory from "history/createBrowserHistory";
import "./global.css";
import "tailwindcss/tailwind.css";
import { observer, Provider } from "mobx-react";
import { RootStore } from "src/stores/RootStore";
import { UserShell } from "src/components/UserShell/UserShell";
import "mobx-react-lite/batchingForReactDom";
import { CustomHistoryAdapter } from "src/routing/CustomHistoryAdapter";
import { HistoryAdapter } from "mobx-state-router";

interface IUPanelHost {
    navigateTo(route: string): Promise<void>;
    setClient(client: IUPanelClient): Promise<void>;
}

interface IUPanelClient {
    setBaseUrl(baseUrl: string): Promise<void>;
    setRoute(route: string): Promise<void>;
    setToken(token: string): Promise<void>;
}

declare global {
    interface Window {
        upanelHostedAppBaseUrl: string;
        upanelClientInitialize: (host: IUPanelHost) => Promise<void>;
    }
}

let host: IUPanelHost;
let root: RootStore;

const ensureInitialized = () => {
    if (root) return;
    const hostedAppBaseUrl = window.upanelHostedAppBaseUrl;
    const history = createBrowserHistory();
    if (!Boolean(hostedAppBaseUrl)) {
        root = new RootStore();
        const historyAdapter = new HistoryAdapter(root.routerStore, history);
        historyAdapter.observeRouterStateChanges();
    } else {
        root = new RootStore(hostedAppBaseUrl);
        root.embeddedAppBaseUrl = window.location.pathname;
        const appName = root.embeddedAppBaseUrl.split("/").pop()!;
        const historyAdapter = new CustomHistoryAdapter(root.routerStore, history, {
            name: appName,
            prefix: root.embeddedAppBaseUrl,
            replaceHistory: true,
        });
        historyAdapter.addLocationChangedListener(async (path) => {
            if (host) await host.navigateTo(path);
        });
        historyAdapter.observeRouterStateChanges();
        window.upanelClientInitialize = async (upanel: IUPanelHost) => {
            host = upanel;
            await host.setClient({
                async setToken(token: string) {
                    root.userRpc.setUserToken(token);
                },
                async setRoute(route: string) {
                    const stub = "";
                    await historyAdapter.goToLocation({
                        pathname: route,
                        hash: route,
                        key: route,
                        search: stub,
                        state: stub,
                    });
                },
                async setBaseUrl(baseUrl: string) {
                    root.hostAppBaseUrl = baseUrl;
                }
            });
        };
    }
};

export const App = observer(() => {
    ensureInitialized();
    return (
        <Provider rootStore={root}>
            <UserShell />
        </Provider>
    );
});
