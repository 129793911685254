import { History, Location } from "history";
import { reaction } from "mobx";
import { parse } from "query-string";
import { RouterState, routerStateToUrl, RouterStore } from "mobx-state-router";
import pathToRegexp from "path-to-regexp";
import { StringMap } from "mobx-state-router/dist/types/router-store";

interface PatternInfo {
    regExp: RegExp;
    keys: pathToRegexp.Key[];
}

interface PatternInfoCache {
    [pattern: string]: PatternInfo;
}

const patternInfoCache: PatternInfoCache = {};

const getPatternInfo = (pattern: string): PatternInfo => {
    const patternInfo = patternInfoCache[pattern];
    if (patternInfo) {
        return patternInfo;
    }

    const keys: pathToRegexp.Key[] = [];
    const regExp = pathToRegexp(pattern, keys);
    const newPatternInfo = { regExp, keys };
    patternInfoCache[pattern] = newPatternInfo;

    return newPatternInfo;
};

export const matchUrl = (url: string, pattern: string) => {
    const { regExp, keys } = getPatternInfo(pattern);
    const match = regExp.exec(url);
    if (!match) {
        return undefined;
    }

    const [matchedUrl, ...values] = match;
    return keys.reduce((params: StringMap, key, index) => {
        params[key.name] = values[index];
        return params;
    }, {});
};

type CustomHistoryAdapterOptions = {
    name: string;
    prefix?: string;
    replaceHistory?: boolean;
};

type CustomHistoryLocationChangedHandler = (pathname: string) => Promise<void>;

export class CustomHistoryAdapter {
    private locationChangedListeners: CustomHistoryLocationChangedHandler[] = [];

    constructor(
        readonly routerStore: RouterStore,
        readonly history: History,
        private readonly options: CustomHistoryAdapterOptions
    ) {
        this.goToLocation(this.history.location);
        this.history.listen((location) => this.goToLocation(location));
    }

    addLocationChangedListener(listener: CustomHistoryLocationChangedHandler) {
        this.locationChangedListeners.push(listener);
    }

    removeLocationChangedListener(listener: CustomHistoryLocationChangedHandler) {
        this.locationChangedListeners = this.locationChangedListeners.filter((item) => item != listener);
    }

    goToLocation = (location: Location): Promise<RouterState> => {
        this.debug(`goToLocation(${JSON.stringify(location)})`);
        if (this.options.prefix && !location.pathname.startsWith(this.options.prefix)) {
            this.debug("unmatched " + location.pathname);
            return this.routerStore.goToNotFound();
        }

        let pathname = this.options.prefix ? location.pathname.replace(this.options.prefix, "") : location.pathname;
        pathname = pathname.replace(/\?.*/, "");
        if (!pathname) pathname = "/";
        
        const routes = this.routerStore.routes;
        let matchingRoute = null;
        let params = undefined;
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            params = matchUrl(pathname, route.pattern);
            if (params) {
                matchingRoute = route;
                break;
            }
        }

        if (matchingRoute) {
            this.debug("matched " + pathname);
            return this.routerStore.goTo(new RouterState(matchingRoute.name, params, parse(location.search)));
        } else {
            this.debug("unmatched " + pathname);
            return this.routerStore.goToNotFound();
        }
    };

    goBack = () => {
        this.history.goBack();
    };

    observeRouterStateChanges = () => {
        reaction(
            () => this.routerStore.routerState,
            async (routerState: RouterState) => {
                const location = this.history.location;
                const currentUrl = `${location.pathname}${location.search}`;
                const routerStateUrl = routerStateToUrl(this.routerStore, routerState);
                const url = this.options.prefix ? this.options.prefix + routerStateUrl : routerStateUrl;
                await this.raisePushEvent(url);
                if (currentUrl !== url) {
                    if (this.options.replaceHistory) {
                        this.history.replace(url);
                    } else {
                        this.history.push(url);
                    }
                    this.debug(`history.push('${url}'), length=${this.history.length}`);
                }
            }
        );
    };

    private debug(str: string): void {
        if (process.env.NODE_ENV === "development") {
            console.log(`HistoryAdapter (${this.options.name}) ` + str);
        }
    }

    private async raisePushEvent(pathname: string): Promise<void> {
        const promises = this.locationChangedListeners.map((listener) => listener(pathname));
        await Promise.all(promises);
    }
}
