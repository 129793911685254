import { lazy } from "react";

export const LoginPage = lazy(() => import("../pages/user/Login/LoginPage"));
export const NotFoundPage = lazy(() => import("../pages/user/NotFound/NotFoundPage"));
export const AccountConfirmPage = lazy(() => import("../pages/user/AccountConfirm/AccountConfirmPage"));
export const MasterPage = lazy(() => import("../pages/user/MasterPage/MasterPage"));
export const AboutCheckPage = lazy(() => import("../pages/user/AboutCheckPage/AboutCheckPage"));
export const TransferPage = lazy(() => import("../pages/user/TransferPage/TransferPage"));
export const PayPage = lazy(() => import("../pages/user/PayPage/PayPage"));
export const ReceivePage = lazy(() => import("../pages/user/ReceivePage/ReceivePage"));
export const NewCheckPage = lazy(() => import("../pages/user/NewCheckPage/NewCheckPage"));
export const PayHistoryPage = lazy(() => import("../pages/user/PayHistoryPage/PayHistoryPage"));
export const AboutTransactionsPage = lazy(() => import("../pages/user/AboutTransactionsPage/AboutTransactionsPage"));
export const ReplenishmentPage = lazy(() => import("../pages/user/ReplenishmentPage/ReplenishmentPage"));
